.c-rainData {
  display: flex;
  align-items: center;
}

.c-insertTime {
  display: flex;
  align-items: center;
  height: rem(70);
  padding: 0 rem(40);
  border-right: rem(1) solid rgba(map-get($theme-colors, "background"), 0.8);
  color: map-get($colors, "white");
  font-size: rem(26);

  span {
    display: inline-block;
     & + span {
       margin-left: rem(20);
     }
  }
}

/* Rainfall.scss */
.c-rainfall {
  display: inline-flex;
  align-items: center;
  padding: 0 rem(40);

  &__icon-left,
  &__icon-right {
    display: inline-flex;
    align-items: center;
  }

  &__icon-left {
    margin-right: rem(12);

    svg {
      width: rem(40);
      height: rem(40);
    }
  }

  &__icon-right {
    margin-left: rem(6);

    svg {
      width: rem(20);
      height: rem(20);
    }
  }

  &__label,
  &__value {
    display: inline-block;
    font-size: rem(26);
    font-weight: 700;
  }

  &__label{
    margin-right: rem(20);
  }
}