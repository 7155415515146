@charset "UTF-8";
@font-face {
  font-family: "SUIT";
  font-weight: 100;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 200;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 300;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Light.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 400;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 500;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 600;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 700;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 800;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 900;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Heavy.woff2") format("woff2");
}
/* 사용자 정의 스크롤바 스타일 */
/* 스크롤바 전체 크기 설정 */
::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

/* 스크롤바 트랙 배경을 투명하게 설정 */
::-webkit-scrollbar-track {
  background: transparent;
}

/* 스크롤바 thumb에 색상 적용 및 opacity 조정 */
::-webkit-scrollbar-thumb {
  background-color: #e5e9ec;
  border-radius: 0.1875rem;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

/* 마우스 hover 및 active 시 opacity 변경 */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  opacity: 1;
}

/* Firefox용: 스크롤바는 제한적이지만, thumb 색상과 폭을 설정할 수 있음 */
* {
  scrollbar-width: thin;
  scrollbar-color: #e5e9ec transparent;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "SUIT";
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
html {
  font-size: 100%;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, button {
  all: unset;
  box-sizing: border-box;
}

/* Header.scss */
.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 0 2.5rem;
  height: 4.375rem;
  background-color: #111111;
}
.header__logo {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 2.5rem;
  border-right: 0.0625rem solid rgba(229, 233, 236, 0.8);
}
.header__center {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header__right {
  display: flex;
  align-items: center;
  width: 4.375rem;
  height: 4.375rem;
}
.header__menu .icon-menu {
  font-size: 1.2rem;
}

/* MainLayout.scss */
/* 전체 레이아웃 */
.main-layout {
  flex: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: 40% 60%; /* 왼쪽 40%, 오른쪽 60% (비율은 자유롭게 조정 가능) */
  position: relative;
  width: 100vw;
  height: calc(100vh - 4.375rem);
  padding: 0.625rem 1.25rem;
}
.main-layout__left {
  display: flex;
  gap: 1.25rem; /* 차트 묶음 간격 */
  height: 100%;
}
.main-layout__right {
  /* 모니터링 영역 */
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: calc(100vw - 50.75rem);
  min-height: 0;
  margin-left: 1.25rem;
}
.main-layout__sensors {
  width: 100%;
  min-height: 0;
}
.main-layout__notice {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  height: 13.75rem;
}
.main-layout__box {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  min-height: 0;
  padding: 0rem;
  background-color: #fff;
}
.main-layout__box-heading {
  position: relative;
  padding-left: 3rem;
  height: 2.375rem;
  color: #0094fa;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.375rem;
}
.main-layout__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.375rem;
  height: 2.375rem;
}
.main-layout__icon svg {
  width: 100%;
  height: 100%;
  fill: #0094fa;
}
.main-layout__box-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  min-height: 0;
}
.main-layout__box-content.paddingTop {
  padding-top: 1.25rem;
}

/* 차트 묶음(그룹) */
.chart-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.chart-group .chart-group__item {
  width: 100%;
}
.chart {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  border: 0.0625rem solid #e5e9ec;
  border-radius: 0.625rem;
  background-color: #fff;
}
.chart.nonPadding {
  padding: 0;
}
.chart__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.875rem;
  margin-bottom: 0.625rem;
}
.chart__title {
  color: #0094fa;
  font-size: 1.25rem;
  font-weight: 700;
}
.chart__content {
  flex: 1;
  position: relative;
}
.chart__content .doughnut {
  position: relative;
  width: 100%;
  height: 10rem;
}
.chart__content .halfGauge {
  position: relative;
  width: 100%;
  height: 6.25rem;
}
.chart__content .line {
  position: relative;
  width: 100%;
  height: 8.75rem;
}

.main-monitoring {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding-top: 1.25rem;
}
.main-monitoring__console {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.main-monitoring__console ul {
  display: flex;
  align-items: center;
}
.main-monitoring__console ul li {
  position: relative;
  padding: 0 0.625rem 0 1.25rem;
  color: #3dd990;
  font-size: 1rem;
  font-weight: 700;
}
.main-monitoring__console ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0.625rem;
  width: 0.1875rem;
  height: 0.1875rem;
  background-color: #3dd990;
}
.main-monitoring__console ul li:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(24, 31, 41, 0.2);
}
.main-monitoring__console ul li:nth-child(2) {
  color: #0094fa;
}
.main-monitoring__console ul li:nth-child(2):before {
  background-color: #0094fa;
}
.main-monitoring__console ul li:nth-child(3) {
  color: #ffd419;
}
.main-monitoring__console ul li:nth-child(3):before {
  background-color: #ffd419;
}
.main-monitoring__console ul li:nth-child(4) {
  color: #ff6462;
}
.main-monitoring__console ul li:nth-child(4):before {
  background-color: #ff6462;
}
.main-monitoring__console ul li:last-child:after {
  display: none;
}
.main-monitoring__count {
  display: flex;
  align-items: center;
}
.main-monitoring__count-total {
  margin-right: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}
.main-monitoring__content {
  overflow: auto;
  position: relative;
  width: 100%;
  height: calc(100vh - 27.375rem);
  padding: 0.625rem;
  background-color: #f2f4f5;
}

.monitoring-group {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0.625rem;
}
.monitoring-group:last-child {
  margin-bottom: 0;
}
.monitoring-group__list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.625rem;
}

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

