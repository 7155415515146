/* Period.scss */

.c-period {
  display: inline-flex;
  align-items: center;
  gap: rem(8);

  &__dash {
    margin: 0 rem(4);
    color: map-get($grays, gray-2);
    font-size: rem(16);
  }

  &--small {
    .c-period__dash {
      font-size: rem(12);
    }
  }
  &--medium {
    .c-period__dash {
      font-size: rem(16);
    }
  }
  &--large {
    .c-period__dash {
      font-size: rem(20);
    }
  }
}
