/* Card.scss */

.c-card {
  padding: rem(20);
  background-color: map-get($colors, "white");
  border: rem(1) solid map-get($theme-colors, "background");
  border-radius: rem(5);
  color: map-get($colors, "text");
  //box-shadow: 0 rem(2) rem(4) rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: rem(10);
  }

  &__icon {
    margin-right: rem(8);
    display: flex;
    align-items: center;
    svg {
      width: rem(20);
      height: rem(20);
    }
  }

  &__title-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    color: map-get($colors, "text");
    font-size: rem(20);
    font-weight: 700;

    &--center {
      text-align: center;
    }
  }

  &__title--success {
    color: map-get($theme-colors, "primary");
  }

  &__title--error {
    color: map-get($theme-colors, "states0"); // 예시: 에러일 경우 빨간색
  }

  &__subtitle {
    margin-top: rem(6);
    font-size: rem(16);
    color: map-get($grays, gray-2);
  }

  &__badge {
    margin-left: auto;
    display: inline-block;
  }

  &__body {
    font-size: rem(16);
    line-height: 1.5;
  }

  &__footer {
    margin-top: rem(10);
    font-size: rem(14);
    text-align: right;
  }

  // 상태별 modifier 클래스
  &--error {
    background-color: rgba(map-get($theme-colors, "error"), .1);
    border: 0;

    .c-card__title {
      color: map-get($theme-colors, "error");
    }

    .c-card__icon {
      svg {
        fill: map-get($theme-colors, "error");
      }
    }
  }
  &--success {
    background-color: rgba(map-get($theme-colors, "success"), .1);
    border: 0;

    .c-card__title {
      color: map-get($theme-colors, "success");
    }

    .c-card__icon {
      svg {
        fill: map-get($theme-colors, "success");
      }
    }
  }
  &--warning {
    background-color: rgba(map-get($theme-colors, "warning"), .1);
    border: 0;

    .c-card__title {
      color: map-get($theme-colors, "warning");
    }

    .c-card__icon {
      svg {
        fill: map-get($theme-colors, "warning");
      }
    }
  }
}
