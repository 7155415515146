/* Radio.scss */
.c-radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &__circle {
    position: relative;
    width: rem(30);
    height: rem(30);
    border: rem(2) solid map-get($theme-colors, "primary");
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(20);
      height: rem(20);
      border-radius: 50%;
      background-color: map-get($colors, "white");
      transform: translate(-50%, -50%);
      transition: background-color 0.2s, border-color 0.2s;
    }
  }

  &__label {
    margin-left: rem(8);
    font-size: rem(20);
    color: map-get($colors, "text");
  }

  &--checked {
    .c-radio__circle:after {
      background-color: map-get($theme-colors, "primary");
    }
  }

  &--disabled {
    cursor: not-allowed;
    .c-radio__circle {
      border-color: map-get($grays, "gray-1");
      background-color: map-get($grays, "gray-1");

      &:after {
        background-color: map-get($theme-colors, "foreground");
      }
    }
    .c-radio__label {
      color: map-get($grays, "gray-2");
    }
  }

  &-group {
    .c-radio {
      margin-right: rem(20);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
