@charset "UTF-8";
/* AccordionItem.scss */
.c-accordion {
  border: 0;
  border-radius: 0.3125rem;
}
.c-accordion__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.125rem;
  padding: 0 1.875rem;
  background: none;
  border: 0.0625rem solid; /* 버튼의 borderColor는 인라인에서 stageColor로 설정 */
  border-radius: 0.3125rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  /* 작은 버튼 스타일 */
}
.c-accordion__button--small {
  height: 2.8125rem;
}
.c-accordion__button.defaultCursor {
  cursor: auto;
}
.c-accordion__title {
  color: #496e88;
  font-size: 1.125rem;
  font-weight: 700;
  /* 텍스트 색상은 인라인 스타일 또는 여기서 지정 가능 */
}
.c-accordion__title.white {
  color: #fff;
}
.c-accordion__icon {
  display: flex;
  align-items: center;
}
.c-accordion__icon svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: #496e88;
}
.c-accordion__icon.white svg {
  fill: #fff;
}
.c-accordion__content {
  padding: 8px 12px;
}

/* Badge.scss */
/* 예시 색상:
   - grade0: 회색, 정상, 닫힘
   - grade1: 초록, 관심
   - grade2: 파랑, 주의, 열림
   - grade3: 주황, 경계
   - grade4: 빨강, 심각
*/
/* 글자색상: solid면 흰색, border면 grade 컬러 */
/* 배경색상: solid면 grade 컬러, border면 투명 */
/* 테두리색상: border면 grade 컬러, solid면 투명 */
.c-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 5rem;
  font-weight: 700;
  line-height: 1.2;
}
.c-badge--small {
  padding: 0 0.5rem;
  min-width: 3.75rem;
  height: 1.75rem;
  border-radius: 0.875rem;
  font-size: 0.75rem;
}
.c-badge--medium {
  padding: 0 1rem;
  height: 2.625rem;
  border-radius: 1.3125rem;
  font-size: 1rem;
}
.c-badge--large {
  padding: 0 1.25rem;
  height: 3.125rem;
  border-radius: 1.5625rem;
  font-size: 1.125rem;
}
.c-badge--grade0.c-badge--solid {
  color: #fff;
  background-color: #7e9eb5;
  border: 0.0625rem solid transparent;
}
.c-badge--grade0.c-badge--border {
  color: #7e9eb5;
  background-color: transparent;
  border: 0.0625rem solid #7e9eb5;
}
.c-badge--grade1.c-badge--solid {
  color: #fff;
  background-color: #3dd990;
  border: 0.0625rem solid transparent;
}
.c-badge--grade1.c-badge--border {
  color: #3dd990;
  background-color: transparent;
  border: 0.0625rem solid #3dd990;
}
.c-badge--grade2.c-badge--solid {
  color: #fff;
  background-color: #0094fa;
  border: 0.0625rem solid transparent;
}
.c-badge--grade2.c-badge--border {
  color: #0094fa;
  background-color: transparent;
  border: 0.0625rem solid #0094fa;
}
.c-badge--grade3.c-badge--solid {
  color: #fff;
  background-color: #ffd419;
  border: 0.0625rem solid transparent;
}
.c-badge--grade3.c-badge--border {
  color: #ffd419;
  background-color: transparent;
  border: 0.0625rem solid #ffd419;
}
.c-badge--grade4.c-badge--solid {
  color: #fff;
  background-color: #ff6462;
  border: 0.0625rem solid transparent;
}
.c-badge--grade4.c-badge--border {
  color: #ff6462;
  background-color: transparent;
  border: 0.0625rem solid #ff6462;
}

/* BadgeGroup.scss */
.c-badge-group {
  display: flex;
  align-items: center;
  /* 뱃지가 하나뿐이면 가운데 정렬, 구분선 숨기기 */
}
.c-badge-group--single {
  justify-content: center;
}
.c-badge-group--single .c-badge-group__divider {
  display: none;
}
.c-badge-group__item {
  display: inline-flex;
  align-items: center;
  /* 제목과 뱃지 사이 간격 */
  /* 구분선(수직 라인) */
}
.c-badge-group__item .c-badge-group__title {
  margin-right: 8px;
  font-size: 14px;
  color: #666;
}
.c-badge-group__item .c-badge-group__divider {
  margin: 0 12px;
  width: 1px;
  height: 16px;
  background-color: #ddd;
}

.c-button {
  display: inline-flex;
  align-items: center;
  height: 3.125rem;
  padding: 0 0.375rem;
  border-radius: 0.3125rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
}
.c-button--full {
  width: 100%;
}
.c-button--full .btn-text {
  display: block;
  width: 100%;
  text-align: center;
}
.c-button--basic {
  border: 0.0625rem solid #0094fa;
  background-color: #fff;
  color: #0094fa;
}
.c-button--primary {
  border: 0.0625rem solid #0094fa;
  background-color: #0094fa;
  color: #fff;
}
.c-button--disabled {
  border: 0.0625rem solid #e5e9ec;
  background-color: #e5e9ec;
  color: #7e848b;
  cursor: not-allowed;
}
.c-button--icon .btn-icon {
  width: 2.25rem;
  height: 2.25rem;
}
.c-button--icon .btn-icon svg {
  width: 2.25rem;
  height: 2.25rem;
}
.c-button--icontext .btn-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icontext .btn-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icontext .btn-text + .btn-icon {
  margin-left: 0.625rem;
}
.c-button--icontext .btn-icon + .btn-text {
  margin-left: 0.625rem;
}
.c-button--large {
  height: 5.125rem;
  padding: 0 1.25rem;
  font-size: 2rem;
}

/* Card.scss */
.c-card {
  padding: 1.25rem;
  background-color: #fff;
  border: 0.0625rem solid #e5e9ec;
  border-radius: 0.3125rem;
  color: #111111;
}
.c-card__header {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}
.c-card__icon {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.c-card__icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.c-card__title-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.c-card__title {
  margin: 0;
  color: #111111;
  font-size: 1.25rem;
  font-weight: 700;
}
.c-card__title--center {
  text-align: center;
}
.c-card__title--success {
  color: #0094fa;
}
.c-card__title--error {
  color: #7e9eb5;
}
.c-card__subtitle {
  margin-top: 0.375rem;
  font-size: 1rem;
  color: #7e848b;
}
.c-card__badge {
  margin-left: auto;
  display: inline-block;
}
.c-card__body {
  font-size: 1rem;
  line-height: 1.5;
}
.c-card__footer {
  margin-top: 0.625rem;
  font-size: 0.875rem;
  text-align: right;
}
.c-card--error {
  background-color: rgba(255, 100, 98, 0.1);
  border: 0;
}
.c-card--error .c-card__title {
  color: #ff6462;
}
.c-card--error .c-card__icon svg {
  fill: #ff6462;
}
.c-card--success {
  background-color: rgba(0, 148, 250, 0.1);
  border: 0;
}
.c-card--success .c-card__title {
  color: #0094fa;
}
.c-card--success .c-card__icon svg {
  fill: #0094fa;
}
.c-card--warning {
  background-color: rgba(245, 180, 0, 0.1);
  border: 0;
}
.c-card--warning .c-card__title {
  color: #f5b400;
}
.c-card--warning .c-card__icon svg {
  fill: #f5b400;
}

/* Checkbox.scss */
.c-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  /* 체크 상태 */
  /* 비활성화 상태 */
}
.c-checkbox__input {
  /* 화면에서 숨기고, 접근성 위해 남겨둠 */
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.c-checkbox__box {
  width: 1.875rem;
  height: 1.875rem;
  border: 0.125rem solid #0094fa;
  border-radius: 0.1875rem;
  display: inline-block;
  box-sizing: border-box;
  transition: background-color 0.2s, border-color 0.2s;
}
.c-checkbox__label {
  margin-left: 0.5rem;
  font-size: 1.25rem;
  color: #111111;
}
.c-checkbox--checked .c-checkbox__box {
  border-color: #0094fa;
  background-color: #0094fa;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 7L5.5 10.5L12 4' stroke='%23ffffff' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}
.c-checkbox--disabled {
  cursor: not-allowed;
}
.c-checkbox--disabled .c-checkbox__box {
  border-color: #d1d2d4;
  background-color: #d1d2d4;
}
.c-checkbox--disabled .c-checkbox__label {
  color: #7e848b;
}
.c-checkbox-group .c-checkbox {
  margin-right: 1.25rem;
}
.c-checkbox-group .c-checkbox:last-child {
  margin-right: 0;
}

.c-date {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.c-date__input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  border: 0.0625rem solid #d1d2d4;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: #111111;
  outline: none;
  background-color: #fff;
  height: 2.8125rem;
  line-height: 2.8125rem;
  /* 기본 캘린더 아이콘 숨기기 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.c-date__input::-webkit-calendar-picker-indicator {
  display: none;
}
.c-date__input:disabled {
  background-color: #d1d2d4;
  color: #7e848b;
  border-color: #7e848b;
  cursor: not-allowed;
}
.c-date__icon {
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.c-date__icon svg {
  width: 1.125rem;
  height: 1.125rem;
  fill: #7e848b;
}
.c-date--small .c-date__input {
  height: 1.875rem;
  font-size: 0.75rem;
  line-height: 1.875rem;
  padding: 0 0.75rem;
}
.c-date--small .c-date__icon {
  right: 0.5rem;
}
.c-date--small .c-date__icon svg {
  width: 0.875rem;
  height: 0.875rem;
}
.c-date--medium .c-date__input {
  height: 2.8125rem;
  font-size: 1.25rem;
  line-height: 2.8125rem;
  padding: 0 1rem;
}
.c-date--medium .c-date__icon {
  right: 0.5rem;
}
.c-date--medium .c-date__icon svg {
  width: 1.125rem;
  height: 1.125rem;
}
.c-date--large .c-date__input {
  height: 3.75rem;
  font-size: 1.25rem;
  line-height: 3.75rem;
  padding: 0 1rem;
}
.c-date--large .c-date__icon {
  right: 0.5rem;
}
.c-date--large .c-date__icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.c-date--disabled .c-date__input {
  background-color: #d1d2d4;
  color: #7e848b;
  border-color: #7e848b;
  cursor: not-allowed;
}

/* Dropdown.scss */
.c-dropdown {
  position: relative;
  display: inline-block;
  /* dropup 모드: 메뉴가 위로 표시되도록 */
}
.c-dropdown--small .c-dropdown__selected,
.c-dropdown--small .c-dropdown__option {
  padding: 0 0.75rem;
  height: 1.875rem;
  font-size: 0.75rem;
  line-height: 1.875rem;
}
.c-dropdown--medium .c-dropdown__selected,
.c-dropdown--medium .c-dropdown__option {
  padding: 0 1rem;
  height: 2.8125rem;
  font-size: 1.25rem;
  line-height: 2.8125rem;
}
.c-dropdown--large .c-dropdown__selected,
.c-dropdown--large .c-dropdown__option {
  height: 3.75rem;
  font-size: 1.25rem;
  padding: 0 1rem;
}
.c-dropdown--disabled .c-dropdown__selected {
  background-color: #d1d2d4;
  color: #7e848b;
  cursor: not-allowed;
  border-color: #d1d2d4;
}
.c-dropdown--dropup .c-dropdown__menu {
  top: auto;
  bottom: 100%;
  margin: 0 0 0.25rem 0;
}
.c-dropdown__selected {
  width: 100%;
  background-color: #fff;
  border: 0.0625rem solid #d1d2d4;
  border-radius: 0.3125rem;
  color: #111111;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}
.c-dropdown__selected:hover {
  background-color: #f2f4f5;
}
.c-dropdown__selected:disabled {
  background-color: #d1d2d4;
  color: #7e848b;
  border-color: #7e848b;
  cursor: not-allowed;
}
.c-dropdown__icon {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}
.c-dropdown__icon svg {
  width: 1rem;
  height: 1rem;
  fill: #7e848b;
}
.c-dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 0.25rem 0 0;
  padding: 0;
  background-color: #fff;
  border: 0.0625rem solid #d1d2d4;
  border-radius: 0.3125rem;
  z-index: 999;
}
.c-dropdown__option {
  border-bottom: 0.0625rem solid #d1d2d4;
  cursor: pointer;
  color: #111111;
}
.c-dropdown__option:hover {
  background-color: #f2f4f5;
}
.c-dropdown__option.selected {
  background-color: #0094fa;
  color: #fff;
}
.c-dropdown__option:last-child {
  border-bottom: 0;
}

/* GroupMonitoringCard.scss */
.c-group-monitoring {
  /* 카드 전체 스타일 (배경, 테두리 등은 필요에 따라 추가) */
  min-width: 14.375rem; /* 예시: 고정 너비 (상황에 맞게 조절) */
  padding: 0;
  border: 0;
  background-color: transparent;
}
.c-group-monitoring__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #0094fa;
  margin-bottom: 0.625rem;
}
.c-group-monitoring__content {
  display: flex;
  align-items: center;
}
.c-group-monitoring__info {
  padding: 0.9375rem 0.875rem 0.9375rem 0;
  margin-right: 0.875rem;
  border-right: 0.0625rem solid #e5e9ec;
}
.c-group-monitoring__info .c-group-monitoring__label {
  color: #111111;
  font-size: 1rem;
  font-weight: 700;
}
.c-group-monitoring__badges {
  /* 2×2 그리드: 배지 최대 4개일 때 바둑판 배열 */
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2열 고정 */
  grid-auto-rows: auto;
  gap: 0.5rem;
  justify-items: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
}

/* InputText.scss */
.c-input {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 0.0625rem solid #496e88;
  border-radius: 0.3125rem;
  padding: 0 1rem;
  color: #111111;
  outline: none;
}
.c-input::placeholder {
  color: #7e848b;
}
.c-input--small {
  height: 1.875rem;
  font-size: 0.75rem;
  line-height: 1.875rem;
}
.c-input--medium {
  height: 2.8125rem;
  font-size: 1.25rem;
  line-height: 2.8125rem;
}
.c-input--large {
  height: 3.75rem;
  font-size: 1.25rem;
  line-height: 3.75rem;
}
.c-input--login {
  height: 5.125rem;
  font-size: 1.5rem;
  line-height: 5.125rem;
}
.c-input--error {
  border-color: #ff6462;
  color: #ff6462;
}
.c-input--error::placeholder {
  color: #ff6462;
}
.c-input--success {
  border-color: #0094fa;
  color: #0094fa;
}
.c-input--success::placeholder {
  color: #0094fa;
}
.c-input--disabled {
  background-color: #d1d2d4;
  color: #7e848b;
  border-color: #d1d2d4;
  cursor: not-allowed;
}
.c-input--disabled::placeholder {
  color: #7e848b;
}

/* Menu.scss */
.c-menu {
  position: relative;
  /* 햄버거 버튼 */
  /* 슬라이드 메뉴 오버레이 */
}
.c-menu__hamburger {
  width: 4.375rem;
  height: 4.375rem;
  background-color: #111111;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  padding: 0;
  z-index: 1001;
}
.c-menu__hamburger .c-menu__line {
  width: 2rem;
  height: 0.25rem;
  background-color: #fff;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.c-menu__hamburger.open {
  background-color: #fff;
  /* 첫 번째 선: 아래로 이동 후 45도 회전 */
  /* 두 번째 선: 사라짐 */
  /* 세 번째 선: 위로 이동 후 -45도 회전 */
}
.c-menu__hamburger.open .c-menu__line {
  background-color: #7e848b;
}
.c-menu__hamburger.open .c-menu__line:nth-child(1) {
  transform: translateY(0.625rem) rotate(45deg);
}
.c-menu__hamburger.open .c-menu__line:nth-child(2) {
  opacity: 0;
}
.c-menu__hamburger.open .c-menu__line:nth-child(3) {
  transform: translateY(-0.625rem) rotate(-45deg);
}
.c-menu__overlay {
  overflow: auto;
  position: fixed;
  top: 4.375rem; /* 헤더 높이 */
  right: -25rem;
  width: 25rem;
  height: calc(100vh - 4.375rem);
  background-color: #fff;
  transition: transform 0.3s ease;
  z-index: 1000;
}
.c-menu__overlay.open {
  transform: translateX(-25rem);
  box-shadow: -1.25rem 0 4.375rem rgba(0, 0, 0, 0.1);
}
.c-menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.c-menu__item {
  display: block;
  border-bottom: 0.0625rem solid #e5e9ec;
}
.c-menu__item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.25rem;
  padding: 0 2.5rem;
  background-color: #fff;
  color: #111111;
  font-size: 1.625rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}
.c-menu__item a:hover {
  background-color: #0094fa;
  color: #fff;
}
.c-menu__item a:hover .c-menu__icon svg {
  fill: #fff;
}
.c-menu__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;
  /* 추가적인 아이콘 스타일은 필요에 따라 수정 */
}
.c-menu__icon svg {
  width: 100%;
  height: 100%;
  fill: #7e848b;
  transition: fill 0.2s;
}

.c-notification {
  flex: 1;
  display: flex;
  align-items: center;
  height: 4.375rem;
  padding: 0 1.875rem;
  background-color: #0094fa;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  animation: flicker 0.5s infinite linear;
}
.c-notification__icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #fff;
  border-radius: 0.3125rem;
}
.c-notification__icon svg {
  width: 2.5rem;
  height: 2.5rem;
  /* 아이콘은 현재 텍스트 색상(currentColor)을 따름 */
  fill: #0094fa;
  animation: flickerIcon 0.5s infinite linear;
}
.c-notification__message {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 1.25rem;
  position: relative;
}
.c-notification__rolling {
  display: inline-block;
  /* 롤링 애니메이션: 10초 동안 우->좌 무한 반복 */
  animation: rolling 10s linear infinite;
}

/* 배경과 텍스트 색상이 깜빡이는 애니메이션 */
@keyframes flicker {
  0%, 100% {
    background-color: #0094fa;
  }
  50% {
    background-color: rgba(0, 148, 250, 0.5);
  }
}
@keyframes flickerIcon {
  0%, 100% {
    fill: #0094fa;
  }
  50% {
    fill: rgba(0, 148, 250, 0.5);
  }
}
/* 메시지 롤링 애니메이션 */
@keyframes rolling {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* Period.scss */
.c-period {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.c-period__dash {
  margin: 0 0.25rem;
  color: #7e848b;
  font-size: 1rem;
}
.c-period--small .c-period__dash {
  font-size: 0.75rem;
}
.c-period--medium .c-period__dash {
  font-size: 1rem;
}
.c-period--large .c-period__dash {
  font-size: 1.25rem;
}

/* Radio.scss */
.c-radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.c-radio__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.c-radio__circle {
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  border: 0.125rem solid #0094fa;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
}
.c-radio__circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s, border-color 0.2s;
}
.c-radio__label {
  margin-left: 0.5rem;
  font-size: 1.25rem;
  color: #111111;
}
.c-radio--checked .c-radio__circle:after {
  background-color: #0094fa;
}
.c-radio--disabled {
  cursor: not-allowed;
}
.c-radio--disabled .c-radio__circle {
  border-color: #d1d2d4;
  background-color: #d1d2d4;
}
.c-radio--disabled .c-radio__circle:after {
  background-color: #f2f4f5;
}
.c-radio--disabled .c-radio__label {
  color: #7e848b;
}
.c-radio-group .c-radio {
  margin-right: 1.25rem;
}
.c-radio-group .c-radio:last-child {
  margin-right: 0;
}

.c-rainData {
  display: flex;
  align-items: center;
}

.c-insertTime {
  display: flex;
  align-items: center;
  height: 4.375rem;
  padding: 0 2.5rem;
  border-right: 0.0625rem solid rgba(229, 233, 236, 0.8);
  color: #fff;
  font-size: 1.625rem;
}
.c-insertTime span {
  display: inline-block;
}
.c-insertTime span + span {
  margin-left: 1.25rem;
}

/* Rainfall.scss */
.c-rainfall {
  display: inline-flex;
  align-items: center;
  padding: 0 2.5rem;
}
.c-rainfall__icon-left, .c-rainfall__icon-right {
  display: inline-flex;
  align-items: center;
}
.c-rainfall__icon-left {
  margin-right: 0.75rem;
}
.c-rainfall__icon-left svg {
  width: 2.5rem;
  height: 2.5rem;
}
.c-rainfall__icon-right {
  margin-left: 0.375rem;
}
.c-rainfall__icon-right svg {
  width: 1.25rem;
  height: 1.25rem;
}
.c-rainfall__label, .c-rainfall__value {
  display: inline-block;
  font-size: 1.625rem;
  font-weight: 700;
}
.c-rainfall__label {
  margin-right: 1.25rem;
}

/* StatesHeading.scss */
.c-states-heading {
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px; /* 공간 확보: 점과 텍스트 사이 */
}

/* 제목 앞에 점(•) 표시 */
.c-states-heading::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 16px;
  /* 점의 색상은 부모의 color 값을 상속받습니다. */
}

/* 상태 레벨별 색상 지정 */
.c-states-heading--state0 {
  color: #9CA3AF; /* 안전 */
}

.c-states-heading--state1 {
  color: #22C55E; /* 관심 */
}

.c-states-heading--state2 {
  color: #3B82F6; /* 주의 */
}

.c-states-heading--state3 {
  color: #F59E0B; /* 경계 */
}

.c-states-heading--state4 {
  color: #EF4444; /* 심각 */
}

/* StepLine.scss */
.c-stepline {
  position: relative;
  line-height: 1.2;
  /* 각 아이템들이 세로로 나열 */
  /* 왼쪽 원 */
  /* 세로 선 */
  /* 오른쪽 내용 영역 */
  /* 동행서비스 알림 라인 */
}
.c-stepline__item {
  position: relative;
  padding: 0.3125rem 0 0.3125rem 1.875rem;
}
.c-stepline__circle {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.625rem;
  height: 0.625rem;
  border: 0.125rem solid;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(-50%);
  z-index: 1;
}
.c-stepline__line {
  position: absolute;
  left: 0.25rem;
  width: 0.125rem;
  height: 100%;
  background-color: inherit;
  top: 0.75rem;
}
.c-stepline__label-value {
  display: flex;
  align-items: center;
}
.c-stepline__content {
  display: flex;
  align-items: center;
}
.c-stepline__label-value {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.c-stepline__label-value-item {
  flex: 1;
  display: flex;
  align-items: center;
}
.c-stepline__label {
  display: block;
  min-width: 3.75rem;
  margin-right: 1.25rem;
  color: #496e88;
  font-size: 1.125rem;
  font-weight: 700;
}
.c-stepline__value {
  flex: 1;
  display: block;
  font-size: 1.125rem;
  color: #111111;
}
.c-stepline__list {
  flex: 1;
}
.c-stepline__list-item {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.25rem;
}
.c-stepline__alert-controls {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.625rem;
}
.c-stepline--small .c-stepline__item {
  padding-left: 1.25rem;
}
.c-stepline--small .c-stepline__label,
.c-stepline--small .c-stepline__value {
  font-size: 0.75rem;
}
.c-stepline--small .c-stepline__label {
  min-width: 3.4375rem;
  margin-right: 0;
}
.c-stepline--small .c-stepline__line {
  top: 0.9rem;
}

/* 단계별 색상 적용 */
.c-stepline--state0 .c-stepline__circle {
  border-color: #7e9eb5;
}
.c-stepline--state0 .c-stepline__line {
  background-color: #7e9eb5;
}

.c-stepline--state1 .c-stepline__circle {
  border-color: #3dd990;
}
.c-stepline--state1 .c-stepline__line {
  background-color: #3dd990;
}

.c-stepline--state2 .c-stepline__circle {
  border-color: #0094fa;
}
.c-stepline--state2 .c-stepline__line {
  background-color: #0094fa;
}

.c-stepline--state3 .c-stepline__circle {
  border-color: #ffd419;
}
.c-stepline--state3 .c-stepline__line {
  background-color: #ffd419;
}

.c-stepline--state4 .c-stepline__circle {
  border-color: #ff6462;
}
.c-stepline--state4 .c-stepline__line {
  background-color: #ff6462;
}

/* small 상태에서의 추가 스타일:
   - c-stepline--small와 단계별 클래스가 동시에 적용될 때,
   - .c-stepline__circle의 border-color를 흰색, background-color를 단계별 색상으로 재정의
*/
.c-stepline--small.c-stepline--state0 .c-stepline__circle {
  border-color: #fff;
  background-color: #7e9eb5;
}

.c-stepline--small.c-stepline--state1 .c-stepline__circle {
  border-color: #fff;
  background-color: #3dd990;
}

.c-stepline--small.c-stepline--state2 .c-stepline__circle {
  border-color: #fff;
  background-color: #0094fa;
}

.c-stepline--small.c-stepline--state3 .c-stepline__circle {
  border-color: #fff;
  background-color: #ffd419;
}

.c-stepline--small.c-stepline--state4 .c-stepline__circle {
  border-color: #fff;
  background-color: #ff6462;
}

/* Switch.scss */
.c-switch-wrapper {
  height: 1.5rem;
}

.c-switch {
  position: relative;
  display: inline-block;
  width: 2.8125rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
}
.c-switch__input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}
.c-switch__input:checked + .c-switch .c-switch__slider {
  background-color: #0094fa;
}
.c-switch__input:checked + .c-switch .c-switch__slider::before {
  background-color: #fff;
  transform: translateX(1.3125rem);
}
.c-switch__slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1d2d4;
  border-radius: 0.75rem;
  transition: background-color 0.2s;
}
.c-switch__slider::before {
  content: "";
  position: absolute;
  left: 0.1875rem;
  top: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: #f2f4f5;
  border-radius: 50%;
  transition: transform 0.2s;
}
.c-switch--disabled {
  cursor: not-allowed;
}
.c-switch--disabled .c-switch__slider {
  background-color: #d1d2d4;
}
.c-switch--disabled .c-switch__slider::before {
  background-color: #f2f4f5;
}

/* Tabs.css */
.c-tabs {
  overflow: hidden;
}
.c-tabs__header {
  display: flex;
}
.c-tabs__button {
  display: block;
  height: 1.875rem;
  padding: 0 0.75rem;
  border: 0.0625rem solid #7e848b;
  background-color: #e5e9ec;
  cursor: pointer;
  color: #7e848b;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.875rem;
  text-align: center;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
.c-tabs__button:first-child {
  border-radius: 0.3125rem 0 0 0.3125rem;
}
.c-tabs__button:last-child {
  border-radius: 0 0.3125rem 0.3125rem 0;
}
.c-tabs__button:hover {
  background-color: #0094fa;
  color: #fff;
}
.c-tabs__button.active {
  background-color: #0094fa;
  color: #fff;
}
.c-tabs__content {
  padding: 1.25rem;
  background-color: #fff;
}

.c-tabs.c-tabs--large .c-tabs__button {
  padding: 0 1.5rem;
  height: 3.75rem;
  font-size: 1.125rem;
}

.c-tabs.c-tabs--line .c-tabs__header {
  display: inline-flex;
}

/* Table.scss */
.c-table {
  width: 100%;
  overflow-x: auto;
  /* 상단 헤더 스타일 */
  /* 사이즈별 헤더 높이 */
  /* 텍스트 정렬 옵션 */
  /* 기본은 left; 이미 설정됨 */
  /* tbody 셀 스타일 */
}
.c-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.c-table .c-table__thead-th {
  padding: 0.5rem 0.75rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: #496e88;
  background-color: #f2f4f5;
  border-bottom: 0.0625rem solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}
.c-table.c-table--size40 .c-table__thead-th {
  height: 40px;
  line-height: 40px;
}
.c-table.c-table--size50 .c-table__thead-th {
  height: 50px;
  line-height: 50px;
}
.c-table.c-table--size60 .c-table__thead-th {
  height: 60px;
  line-height: 60px;
}
.c-table.c-table--align-center table {
  text-align: center;
}
.c-table.c-table--align-right table {
  text-align: right;
}
.c-table tbody tr {
  border-bottom: 0.0625rem solid #ddd;
}
.c-table tbody tr:last-child {
  border-bottom: none;
}
.c-table tbody td, .c-table tbody .c-table__row-th {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  color: #666;
}
.c-table tbody .c-table__row-th {
  background-color: #f9f9f9;
  font-weight: 700;
}
.c-table__empty {
  text-align: center;
  color: #999;
}

/* TabsLine.css */
/*
   BEM 네이밍을 사용해 탭 헤더 아래쪽에 라인(underline) 스타일을 적용.
   size="large"로 확장할 수 있는 Modifier 클래스도 정의.
*/
.c-tabs-line__header {
  display: flex;
  border-bottom: 1px solid #7e848b;
}
.c-tabs-line__button {
  position: relative;
  margin-bottom: -0.0625rem;
  padding: 0 3.125rem;
  height: 5rem;
  background: #f2f4f5;
  border: none;
  border-bottom: 1px solid #7e848b;
  cursor: pointer;
  color: #7e848b;
  font-size: 2rem;
  font-weight: 700;
  line-height: 5rem;
  transition: color 0.2s ease;
}
.c-tabs-line__button:hover {
  color: #111111;
}
.c-tabs-line__button.active {
  color: #111111;
}
.c-tabs-line__button.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 5px;
  background-color: #0094fa;
}
.c-tabs-line__content {
  padding: 1rem;
}
.c-tabs-line--large .c-tabs-line__button {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
}

.heading {
  font-weight: 700;
  line-height: 1.25;
  color: #111111;
}
.heading--60 {
  font-size: 3.75rem;
}
.heading--32 {
  font-size: 2rem;
}
.heading--26 {
  font-size: 1.625rem;
}
.heading--24 {
  font-size: 1.5rem;
}
.heading--20 {
  font-size: 1.25rem;
}
.dark-mode .heading {
  color: #fff;
}

.title {
  font-weight: 700;
  line-height: 1.25;
  color: #111111;
}
.title--18 {
  font-size: 1.125rem;
}
.title--16 {
  font-size: 1rem;
}
.title--14 {
  font-size: 0.875rem;
}
.title--12 {
  font-size: 0.75rem;
}
.dark-mode .title {
  color: #fff;
}

.text {
  font-weight: 500;
  font-size: 1.125rem;
  color: #111111;
}
.text--18 {
  font-size: 1.125rem;
}
.text--16 {
  font-size: 1rem;
}
.text--12 {
  font-size: 0.75rem;
}
.text--10 {
  font-size: 0.625rem;
  font-weight: 400;
}
.dark-mode .text {
  color: #fff;
}

/* TestComponents.scss */
.test-page {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.test-block {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.test-block h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

.test-group {
  margin-bottom: 20px;
}
.test-group h4 {
  margin: 0 0 10px;
  font-size: 1rem;
  font-weight: 600;
}

