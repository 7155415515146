.c-date {
  position: relative;
  display: inline-flex;
  align-items: center;

  &__input {
    width: 100%;
    box-sizing: border-box;
    padding: 0 rem(16);
    border: rem(1) solid map-get($grays, gray-1);
    border-radius: rem(5);
    font-size: rem(14);
    color: map-get($colors, 'text');
    outline: none;
    background-color: map-get($colors, 'white');
    height: rem(45);
    line-height: rem(45);

    /* 기본 캘린더 아이콘 숨기기 */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &:disabled {
      background-color: map-get($grays, gray-1);
      color: map-get($grays, gray-2);
      border-color: map-get($grays, gray-2);
      cursor: not-allowed;
    }
  }

  &__icon {
    position: absolute;
    right: rem(8);
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: rem(18);
      height: rem(18);
      fill: map-get($grays, gray-2);
    }
  }

  // 사이즈별 스타일
  &--small {
    .c-date__input {
      height: rem(30);
      font-size: rem(12);
      line-height: rem(30);
      padding: 0 rem(12);
    }
    .c-date__icon {
      right: rem(8);
      svg {
        width: rem(14);
        height: rem(14);
      }
    }
  }

  &--medium {
    .c-date__input {
      height: rem(45);
      font-size: rem(20);
      line-height: rem(45);
      padding: 0 rem(16);
    }
    .c-date__icon {
      right: rem(8);
      svg {
        width: rem(18);
        height: rem(18);
      }
    }
  }

  &--large {
    .c-date__input {
      height: rem(60);
      font-size: rem(20);
      line-height: rem(60);
      padding: 0 rem(16);
    }
    .c-date__icon {
      right: rem(8);
      svg {
        width: rem(20);
        height: rem(20);
      }
    }
  }

  &--disabled {
    .c-date__input {
      background-color: map-get($grays, gray-1);
      color: map-get($grays, gray-2);
      border-color: map-get($grays, gray-2);
      cursor: not-allowed;
    }
  }
}
