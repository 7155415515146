@import "./mixin";
@import "./webfont";
@import "./reset";
@import "./components/logo";
@import "./components/inputText";
@import "./components/button";

.login {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;

  &__header {
    display: flex;
    align-items: center;
    //position: absolute;
    width: 100%;
    padding: rem(25) rem(80);
    border: rem(1) solid map-get($theme-colors, "background");
  }

  &__logos {
    display: inline-flex;
    align-items: center;
  }

  &__logos-item {
    position: relative;
    padding: 0 rem(41) 0 rem(40);

    &:after  {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: rem(1);
      height: rem(60);
      margin-top: rem(-30);
      background-color: rgba(map-get($colors, "navy"), 0.5);
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__bg-img,
  &__container {
    flex: 1;
    position: relative;
    height: 100%;
  }

  &__bg-img {
    background-image: url(../../img/bg/bg__login.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: rem(820) rem(637);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__box {
    width: rem(740);
    padding: rem(80) rem(100);
    border: 0;
    border-radius: rem(20);
    background-color: map-get($theme-colors, "background");
  }

  &__title {
    margin-bottom: rem(50);
    font-size: rem(60);
    font-weight: 700;
  }

  &__field {
    margin-bottom: rem(30);

    &:last-child {
      margin-bottom: rem(40);
    }
  }

  &__label {
    display: block;
    padding-left: rem(10);
    margin-bottom: rem(10);
    color: map-get($colors, "navy");
    font-size: rem(20);
    font-weight: 700;
  }

  &__contact {
    width: rem(740);
    margin-top: rem(30);
    text-align: right;
    color: map-get($colors, "navy");
    font-size: rem(20);
    font-weight: 700;
  }
}

