@import "./mixin";
@import "./components/accordionItem";
@import "./components/badge";
@import "./components/badgegroup";
@import "./components/button";
@import "./components/card";
@import "./components/checkbox";
@import "./components/date";
@import "./components/dropdown";
@import "./components/groupMonitoringCard";
@import "./components/inputText";
@import "./components/menu";
@import "./components/notification";
@import "./components/period";
@import "./components/radio";
@import "./components/raindata"; // raindata, fainfall, insertime
@import "./components/statesHeading";
@import "./components/stepLine";
@import "./components/switch";
@import "./components/tabs";
@import "./components/table";
@import "./components/tabsline";

.heading {
  font-weight: 700;
  line-height: 1.25;
  color: map-get($colors, text);

  &--60 {
    font-size: rem(60);
  }
  &--32 {
    font-size: rem(32);
  }
  &--26 {
    font-size: rem(26);
  }
  &--24 {
    font-size: rem(24);
  }
  &--20 {
    font-size: rem(20);
  }

  @include dark-mode {
    color: map-get($colors, d-text);
  }
}

.title {
  font-weight: 700;
  line-height: 1.25;
  color: map-get($colors, text);

  &--18 {
    font-size: rem(18);
  }
  &--16 {
    font-size: rem(16);
  }
  &--14 {
    font-size: rem(14);
  }
  &--12 {
    font-size: rem(12);
  }

  @include dark-mode {
    color: map-get($colors, d-text);
  }
}

.text {
  font-weight: 500;
  font-size: rem(18);
  color: map-get($colors, text);

  &--18 {
    font-size: rem(18);
  }
  &--16 {
    font-size: rem(16);
  }
  &--12 {
    font-size: rem(12);
  }
  &--10 {
    font-size: rem(10);
    font-weight: 400;
  }

  @include dark-mode {
    color: map-get($colors, d-text);
  }
}

/* TestComponents.scss */
.test-page {
  padding: 20px;
  background-color: #f9f9f9; // 페이지 배경색 예시
  font-family: Arial, sans-serif;
}

.test-block {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;    // 블록 배경색
  border: 1px solid #ddd;    // 블록 테두리
  border-radius: 6px;

  h2 {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
  }
}

.test-group {
  margin-bottom: 20px;

  h4 {
    margin: 0 0 10px;
    font-size: 1rem;
    font-weight: 600;
  }
}

