/* Table.scss */
.c-table {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  /* 상단 헤더 스타일 */
  .c-table__thead-th {
    padding: rem(8) rem(12);
    font-weight: 700;
    font-size: rem(14);
    color: map-get($colors, "navy");
    background-color: map-get($theme-colors, "foreground");
    border-bottom: rem(1) solid #ddd;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  /* 사이즈별 헤더 높이 */
  &.c-table--size40 {
    .c-table__thead-th {
      height: 40px;
      line-height: 40px;
    }
  }
  &.c-table--size50 {
    .c-table__thead-th {
      height: 50px;
      line-height: 50px;
    }
  }
  &.c-table--size60 {
    .c-table__thead-th {
      height: 60px;
      line-height: 60px;
    }
  }

  /* 텍스트 정렬 옵션 */
  &.c-table--align-center {
    table {
      text-align: center;
    }
  }
  &.c-table--align-right {
    table {
      text-align: right;
    }
  }
  /* 기본은 left; 이미 설정됨 */

  /* tbody 셀 스타일 */
  tbody {
    tr {
      border-bottom: rem(1) solid #ddd;
      &:last-child {
        border-bottom: none;
      }
    }
    td, .c-table__row-th {
      padding: rem(8) rem(12);
      font-size: rem(14);
      color: #666;
    }
    .c-table__row-th {
      background-color: #f9f9f9;
      font-weight: 700;
    }
  }

  &__empty {
    text-align: center;
    color: #999;
  }
}
