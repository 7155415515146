/* Header.scss */
.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //justify-content: space-between;
  padding: 0 0 0 rem(40);
  height: rem(70);
  background-color: map-get($colors, "text");

  &__logo {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: rem(40);
    border-right: rem(1) solid rgba(map-get($theme-colors, "background"), 0.8);
  }

  &__center {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__right {
    display: flex;
    align-items: center;
    width: rem(70);
    height: rem(70);
  }

  &__menu {
    .icon-menu {
      font-size: 1.2rem; // 햄버거 메뉴 아이콘
    }
  }
}
