/* Checkbox.scss */
.c-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__input {
    /* 화면에서 숨기고, 접근성 위해 남겨둠 */
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &__box {
    width: rem(30);
    height: rem(30);
    border: rem(2) solid map-get($theme-colors, "primary");
    border-radius: rem(3);
    display: inline-block;
    box-sizing: border-box;
    transition: background-color 0.2s, border-color 0.2s;
  }

  &__label {
    margin-left: rem(8);
    font-size: rem(20);
    color: map-get($colors, "text");
  }

  /* 체크 상태 */
  &--checked {
    .c-checkbox__box {
      border-color: map-get($theme-colors, "primary");
      background-color: map-get($theme-colors, "primary");
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 7L5.5 10.5L12 4' stroke='%23ffffff' stroke-width='2'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  /* 비활성화 상태 */
  &--disabled {
    cursor: not-allowed;
    .c-checkbox__box {
      border-color: map-get($grays, "gray-1");
      background-color: map-get($grays, "gray-1");
    }
    .c-checkbox__label {
      color: map-get($grays, "gray-2");
    }
  }

  &-group {
    .c-checkbox {
      margin-right: rem(20);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
