@import "./variables";

@mixin mobile {
  @media (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin dark-mode {
  .dark-mode & {
    @content;
  }
}

@function rem($pixels, $base: 16) {
  @return ($pixels / $base) * 1rem;
}