/* BadgeGroup.scss */
.c-badge-group {
  display: flex;
  align-items: center;

  /* 뱃지가 하나뿐이면 가운데 정렬, 구분선 숨기기 */
  &--single {
    justify-content: center;

    .c-badge-group__divider {
      display: none;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;

    /* 제목과 뱃지 사이 간격 */
    .c-badge-group__title {
      margin-right: 8px;
      font-size: 14px;
      color: #666;
    }

    /* 구분선(수직 라인) */
    .c-badge-group__divider {
      margin: 0 12px;
      width: 1px;
      height: 16px;
      background-color: #ddd;
    }
  }
}
