.c-notification {
  flex: 1;
  display: flex;
  align-items: center;
  height: rem(70);
  padding: 0 rem(30);
  background-color: map-get($theme-colors, "primary");
  color: map-get($colors, "white");
  font-size: rem(20);
  font-weight: 700;
  animation: flicker 0.5s infinite linear;

  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(50);
    height: rem(50);
    background-color: map-get($colors, "white");
    border-radius: rem(5);
  }

  &__icon {
    svg {
      width: rem(40);
      height: rem(40);
      /* 아이콘은 현재 텍스트 색상(currentColor)을 따름 */
      fill: map-get($theme-colors, "primary");
      animation: flickerIcon 0.5s infinite linear;
    }
  }

  &__message {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    margin-left: rem(20);
    position: relative;
  }

  &__rolling {
    display: inline-block;
    /* 롤링 애니메이션: 10초 동안 우->좌 무한 반복 */
    animation: rolling 10s linear infinite;
  }
}

/* 배경과 텍스트 색상이 깜빡이는 애니메이션 */
@keyframes flicker {
  0%, 100% {
    background-color: map-get($theme-colors, "primary");
  }
  50% {
    background-color: rgba(map-get($theme-colors, "primary"), .5);
  }
}
@keyframes flickerIcon {
  0%, 100% {
    fill: map-get($theme-colors, "primary");
  }
  50% {
    fill: rgba(map-get($theme-colors, "primary"), .5);
  }
}

/* 메시지 롤링 애니메이션 */
@keyframes rolling {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}