@import "./mixin";
@import "./webfont";
@import "./reset";
@import "./layout/header";
@import "./layout/main";
@import "./layout/monitoringGroup";

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
