/* 사용자 정의 스크롤바 스타일 */
/* 스크롤바 전체 크기 설정 */
::-webkit-scrollbar {
  width: rem(6);
  height: rem(6);
}

/* 스크롤바 트랙 배경을 투명하게 설정 */
::-webkit-scrollbar-track {
  background: transparent;
}

/* 스크롤바 thumb에 색상 적용 및 opacity 조정 */
::-webkit-scrollbar-thumb {
  background-color: map-get($theme-colors, "background");
  border-radius: rem(3);
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

/* 마우스 hover 및 active 시 opacity 변경 */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  opacity: 1;
}

/* Firefox용: 스크롤바는 제한적이지만, thumb 색상과 폭을 설정할 수 있음 */
* {
  scrollbar-width: thin;
  scrollbar-color: map-get($theme-colors, "background") transparent;
}