/* MainLayout.scss */

/* 전체 레이아웃 */
.main-layout {
  flex: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: 40% 60%; /* 왼쪽 40%, 오른쪽 60% (비율은 자유롭게 조정 가능) */
  //gap: rem(20); /* 컬럼 간격 */
  position: relative;
  width: 100vw;
  height: calc(100vh - rem(70));
  padding: rem(10) rem(20);

  &__left {
    display: flex;
    gap: rem(20); /* 차트 묶음 간격 */
    height: 100%;
  }

  &__right {
    /* 모니터링 영역 */
    display: flex;
    flex-direction: column;
    gap: rem(20);
    //height: 100%;
    width: calc(100vw - rem(812));
    min-height: 0;
    margin-left: rem(20);
  }

  &__sensors {
    width: 100%;
    min-height: 0;
  }

  &__notice {
    display: flex;
    align-items: center;
    gap: rem(20);
    height: rem(220);
  }

  &__box {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    min-height: 0;
    padding: rem(0);
    //border: rem(1) solid map-get($theme-colors, "background");
    //border-radius: rem(10);
    background-color: map-get($colors, "white");
  }

  &__box-heading {
    position: relative;
    padding-left: rem(48);
    height: rem(38);
    color: map-get($theme-colors, "primary");
    font-size: rem(26);
    font-weight: 700;
    line-height: rem(38);
  }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(38);
    height: rem(38);

    svg {
      width: 100%;
      height: 100%;
      fill: map-get($theme-colors, "primary");
    }
  }

  &__box-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    min-height: 0;

    &.paddingTop {
      padding-top: rem(20);
    }
  }
}

/* 차트 묶음(그룹) */
.chart-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: rem(20);

  .chart-group__item {
    width: 100%;

    &:last-child {

    }
  }
}

.chart {
  display: flex;
  //flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100%;
  padding: rem(20);
  border: rem(1) solid map-get($theme-colors, "background");
  border-radius: rem(10);
  background-color: map-get($colors, "white");

  &.nonPadding {
    padding: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: rem(30);
    margin-bottom: rem(10);
  }

  &__title {
    color: map-get($theme-colors, "primary");
    font-size: rem(20);
    font-weight: 700;
  }

  &__content {
    flex: 1;
    position: relative;

    .doughnut {
      position: relative;
      width: 100%;
      height: rem(160);
    }

    .halfGauge {
      position: relative;
      width: 100%;
      height: rem(100);
    }

    .line {
      position: relative;
      width: 100%;
      height: rem(140);
    }
  }
}

.main-monitoring {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding-top: rem(20);

  &__console {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20);

    ul {
      display: flex;
      align-items: center;

      li {
        position: relative;
        padding: 0 rem(10) 0 rem(20);
        color: map-get($theme-colors, "states1");
        font-size: rem(16);
        font-weight: 700;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: rem(10);
          width: rem(3);
          height: rem(3);
          background-color: map-get($theme-colors, "states1");
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: rem(1);
          height: 100%;
          background-color: rgba(map-get($grays, "gray-4"), 0.2);
        }

        &:nth-child(2) {
          color: map-get($theme-colors, "states2");

          &:before {
            background-color: map-get($theme-colors, "states2");
          }
        }
        &:nth-child(3) {
          color: map-get($theme-colors, "states3");

          &:before {
            background-color: map-get($theme-colors, "states3");
          }
        }
        &:nth-child(4) {
          color: map-get($theme-colors, "states4");

          &:before {
            background-color: map-get($theme-colors, "states4");
          }
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__count {
    display: flex;
    align-items: center;
  }

  &__count-total {
    margin-right: rem(20);
    font-size: rem(16);
    font-weight: 700;
  }

  &__content {
    overflow: auto;
    position: relative;
    width: 100%;
    height: calc(100vh - rem(438));
    padding: rem(10);
    background-color: map-get($theme-colors, "foreground");
  }
}
