/* GroupMonitoringCard.scss */
.c-group-monitoring {
  /* 카드 전체 스타일 (배경, 테두리 등은 필요에 따라 추가) */
  min-width: rem(230); /* 예시: 고정 너비 (상황에 맞게 조절) */
  padding: 0;
  border:0;
  background-color: transparent;

  &__title {
    font-size: rem(20);
    font-weight: 700;
    color: map-get($theme-colors, "primary");
    margin-bottom: rem(10);
  }

  &__content {
    display: flex;
    align-items: center;
  }
  &__info {
    padding: rem(15) rem(14) rem(15) 0;
    margin-right: rem(14);
    border-right: rem(1) solid map-get($theme-colors, "background");

    .c-group-monitoring__label {
      color: map-get($colors, "text");
      font-size: rem(16);
      font-weight: 700;
    }
  }

  &__badges {
    /* 2×2 그리드: 배지 최대 4개일 때 바둑판 배열 */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2열 고정 */
    grid-auto-rows: auto;
    gap: rem(8);
    justify-items: center; /* 가로 중앙 정렬 */
    align-items: center;   /* 세로 중앙 정렬 */
  }
}
