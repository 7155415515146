@charset "UTF-8";
/* TestIcons.css - Icons 테스트 페이지 스타일 */
.test-icons {
  padding: 20px;
  font-family: Arial, sans-serif;
}
.test-icons__title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}
.test-icons__controls {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.test-icons__color-code {
  font-weight: bold;
}
.test-icons__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.test-icons__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}
.test-icons__preview {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.test-icons__name {
  margin-top: 5px;
  font-size: 0.8rem;
  text-align: center;
}

