@charset "UTF-8";
@font-face {
  font-family: "SUIT";
  font-weight: 100;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 200;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 300;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Light.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 400;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 500;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 600;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 700;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 800;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 900;
  src: url("../../fonts/Suit/SUIT-woff2/SUIT-Heavy.woff2") format("woff2");
}
/* 사용자 정의 스크롤바 스타일 */
/* 스크롤바 전체 크기 설정 */
::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

/* 스크롤바 트랙 배경을 투명하게 설정 */
::-webkit-scrollbar-track {
  background: transparent;
}

/* 스크롤바 thumb에 색상 적용 및 opacity 조정 */
::-webkit-scrollbar-thumb {
  background-color: #e5e9ec;
  border-radius: 0.1875rem;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

/* 마우스 hover 및 active 시 opacity 변경 */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  opacity: 1;
}

/* Firefox용: 스크롤바는 제한적이지만, thumb 색상과 폭을 설정할 수 있음 */
* {
  scrollbar-width: thin;
  scrollbar-color: #e5e9ec transparent;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "SUIT";
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
html {
  font-size: 100%;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, button {
  all: unset;
  box-sizing: border-box;
}

/* Logo.scss */
.c-logo {
  display: inline-flex;
  align-items: center;
  /* 색상 옵션 */
  /* 사이즈 옵션 */
}
.c-logo__link {
  display: inline-flex;
  align-items: center;
}
.c-logo__icon {
  display: inline-flex;
  align-items: center;
  margin-right: 0.75rem;
}
.c-logo__icon svg {
  width: 3.125rem;
  height: 3.125rem;
  fill: #496e88 !important;
}
.c-logo__text {
  display: flex;
  flex-direction: column;
  color: #496e88;
}
.c-logo__main {
  font-weight: 700;
  font-size: 1.25rem;
}
.c-logo__sub {
  font-size: 0.9375rem;
  color: #496e88;
}
.c-logo--white .c-logo__icon svg {
  fill: #fff !important;
}
.c-logo--white .c-logo__main, .c-logo--white .c-logo__sub {
  color: #fff;
}
.c-logo--gray .c-logo__icon svg {
  fill: #7e848b !important;
}
.c-logo--gray .c-logo__main, .c-logo--gray .c-logo__sub {
  color: #7e848b;
}
.c-logo--navy .c-logo__icon svg {
  fill: #496e88 !important;
}
.c-logo--navy .c-logo__main, .c-logo--navy .c-logo__sub {
  color: #496e88;
}
.c-logo--small .c-logo__icon svg {
  width: 1.875rem;
  height: 1.875rem;
}
.c-logo--small .c-logo__main {
  font-size: 1rem;
}
.c-logo--small .c-logo__sub {
  font-size: 0.75rem;
}
.c-logo--medium .c-logo__icon svg {
  width: 3.125rem;
  height: 3.125rem;
}
.c-logo--medium .c-logo__main {
  font-size: 1.25rem;
}
.c-logo--medium .c-logo__sub {
  font-size: 0.9375rem;
}
.c-logo--large .c-logo__icon svg {
  width: 4.375rem;
  height: 4.375rem;
}
.c-logo--large .c-logo__main {
  font-size: 1.875rem;
}
.c-logo--large .c-logo__sub {
  font-size: 1.5rem;
}

/* InputText.scss */
.c-input {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 0.0625rem solid #496e88;
  border-radius: 0.3125rem;
  padding: 0 1rem;
  color: #111111;
  outline: none;
}
.c-input::placeholder {
  color: #7e848b;
}
.c-input--small {
  height: 1.875rem;
  font-size: 0.75rem;
  line-height: 1.875rem;
}
.c-input--medium {
  height: 2.8125rem;
  font-size: 1.25rem;
  line-height: 2.8125rem;
}
.c-input--large {
  height: 3.75rem;
  font-size: 1.25rem;
  line-height: 3.75rem;
}
.c-input--login {
  height: 5.125rem;
  font-size: 1.5rem;
  line-height: 5.125rem;
}
.c-input--error {
  border-color: #ff6462;
  color: #ff6462;
}
.c-input--error::placeholder {
  color: #ff6462;
}
.c-input--success {
  border-color: #0094fa;
  color: #0094fa;
}
.c-input--success::placeholder {
  color: #0094fa;
}
.c-input--disabled {
  background-color: #d1d2d4;
  color: #7e848b;
  border-color: #d1d2d4;
  cursor: not-allowed;
}
.c-input--disabled::placeholder {
  color: #7e848b;
}

.c-button {
  display: inline-flex;
  align-items: center;
  height: 3.125rem;
  padding: 0 0.375rem;
  border-radius: 0.3125rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
}
.c-button--full {
  width: 100%;
}
.c-button--full .btn-text {
  display: block;
  width: 100%;
  text-align: center;
}
.c-button--basic {
  border: 0.0625rem solid #0094fa;
  background-color: #fff;
  color: #0094fa;
}
.c-button--primary {
  border: 0.0625rem solid #0094fa;
  background-color: #0094fa;
  color: #fff;
}
.c-button--disabled {
  border: 0.0625rem solid #e5e9ec;
  background-color: #e5e9ec;
  color: #7e848b;
  cursor: not-allowed;
}
.c-button--icon .btn-icon {
  width: 2.25rem;
  height: 2.25rem;
}
.c-button--icon .btn-icon svg {
  width: 2.25rem;
  height: 2.25rem;
}
.c-button--icontext .btn-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icontext .btn-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icontext .btn-text + .btn-icon {
  margin-left: 0.625rem;
}
.c-button--icontext .btn-icon + .btn-text {
  margin-left: 0.625rem;
}
.c-button--large {
  height: 5.125rem;
  padding: 0 1.25rem;
  font-size: 2rem;
}

.login {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
}
.login__header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5625rem 5rem;
  border: 0.0625rem solid #e5e9ec;
}
.login__logos {
  display: inline-flex;
  align-items: center;
}
.login__logos-item {
  position: relative;
  padding: 0 2.5625rem 0 2.5rem;
}
.login__logos-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.0625rem;
  height: 3.75rem;
  margin-top: -1.875rem;
  background-color: rgba(73, 110, 136, 0.5);
}
.login__logos-item:first-child {
  padding-left: 0;
}
.login__logos-item:last-child {
  padding-right: 0;
}
.login__logos-item:last-child:after {
  display: none;
}
.login__content {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.login__bg-img, .login__container {
  flex: 1;
  position: relative;
  height: 100%;
}
.login__bg-img {
  background-image: url(../../img/bg/bg__login.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 51.25rem 39.8125rem;
}
.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login__box {
  width: 46.25rem;
  padding: 5rem 6.25rem;
  border: 0;
  border-radius: 1.25rem;
  background-color: #e5e9ec;
}
.login__title {
  margin-bottom: 3.125rem;
  font-size: 3.75rem;
  font-weight: 700;
}
.login__field {
  margin-bottom: 1.875rem;
}
.login__field:last-child {
  margin-bottom: 2.5rem;
}
.login__label {
  display: block;
  padding-left: 0.625rem;
  margin-bottom: 0.625rem;
  color: #496e88;
  font-size: 1.25rem;
  font-weight: 700;
}
.login__contact {
  width: 46.25rem;
  margin-top: 1.875rem;
  text-align: right;
  color: #496e88;
  font-size: 1.25rem;
  font-weight: 700;
}

