/* Menu.scss */
.c-menu {
  position: relative;

  /* 햄버거 버튼 */
  &__hamburger {
    width: rem(70);
    height: rem(70);
    background-color: map-get($colors, "text");
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(6);
    padding: 0;
    z-index: 1001;

    .c-menu__line {
      width: rem(32);
      height: rem(4);
      background-color: map-get($colors, "white");
      transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
    }

    &.open {
      background-color: map-get($colors, "white");

      .c-menu__line {
        background-color: map-get($grays, "gray-2");
      }

      /* 첫 번째 선: 아래로 이동 후 45도 회전 */
      .c-menu__line:nth-child(1) {
        transform: translateY(rem(10)) rotate(45deg);
      }
      /* 두 번째 선: 사라짐 */
      .c-menu__line:nth-child(2) {
        opacity: 0;
      }
      /* 세 번째 선: 위로 이동 후 -45도 회전 */
      .c-menu__line:nth-child(3) {
        transform: translateY(rem(-10)) rotate(-45deg);
      }
    }
  }

  /* 슬라이드 메뉴 오버레이 */
  &__overlay {
    overflow: auto;
    position: fixed;
    top: rem(70); /* 헤더 높이 */
    right: rem(-400);
    width: rem(400);
    height: calc(100vh - rem(70));
    background-color: map-get($colors, "white");
    transition: transform 0.3s ease;
    z-index: 1000;

    &.open {
      transform: translateX(rem(-400));
      box-shadow: rem(-20) 0 rem(70) rgba(0, 0, 0, 0.1);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: block;
    border-bottom: rem(1) solid map-get($theme-colors, "background");

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: rem(100);
      padding: 0 rem(40);
      background-color: map-get($colors, "white");
      color: map-get($colors, "text");
      font-size: rem(26);
      font-weight: 700;
      cursor: pointer;
      transition: background-color 0.2s, color 0.2s;

      &:hover {
        background-color: map-get($theme-colors, "primary");
        color: map-get($colors, "white");

        .c-menu__icon {
          svg {
            fill: map-get($colors, "white");
          }
        }
      }
    }
  }

  &__icon {
    width: rem(40);
    height: rem(40);
    margin-right: rem(20);
    /* 추가적인 아이콘 스타일은 필요에 따라 수정 */
    svg {
      width: 100%;
      height: 100%;
      fill: map-get($grays, "gray-2");
      transition: fill 0.2s;
    }
  }
}


