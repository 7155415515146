.c-button {
  display: inline-flex;
  align-items: center;
  height: rem(50);
  padding: 0 rem(6);
  border-radius: rem(5);
  font-size: rem(20);
  font-weight: 700;
  line-height: 1;
  cursor: pointer;

  &--full {
    width: 100%;
    .btn-text {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  &--basic {
    border: rem(1) solid map-get($theme-colors, primary);
    background-color: map-get($colors, 'white');
    color: map-get($theme-colors, primary);
  }

  &--primary {
    border: rem(1) solid map-get($theme-colors, primary);
    background-color: map-get($theme-colors, primary);
    color: map-get($colors, 'white');
  }

  &--disabled {
    border: rem(1) solid map-get($theme-colors, background);
    background-color: map-get($theme-colors, background);
    color: map-get($grays, 'gray-2');
    cursor: not-allowed;
  }

  &--icon {
    .btn-icon {
      width: rem(36);
      height: rem(36);

      svg {
        width: rem(36);
        height: rem(36);
      }
    }

  }

  &--icontext {
    .btn-icon {
      width: rem(24);
      height: rem(24);

      svg {
        width: rem(24);
        height: rem(24);
      }
    }


    .btn-text + .btn-icon {
      margin-left: rem(10);
    }

    .btn-icon + .btn-text {
      margin-left: rem(10);
    }
  }

  &--large {
    height: rem(82);
    padding: 0 rem(20);
    font-size: rem(32);
  }
}