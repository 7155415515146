/* Logo.scss */
.c-logo {
  display: inline-flex;
  align-items: center;

  &__link {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    margin-right: rem(12);

    svg {
      width: rem(50);
      height: rem(50);
      fill: map-get($colors, "navy") !important; // 기본 navy 색 계열
    }
  }

  &__text {
    display: flex;
    flex-direction: column; // 메인 텍스트와 서브 텍스트를 위아래로 배치
    color: map-get($colors, "navy");
  }

  &__main {
    font-weight: 700;
    font-size: rem(20);
  }

  &__sub {
    font-size: rem(15);
    color: map-get($colors, "navy");
  }

  /* 색상 옵션 */
  &--white {
    .c-logo__icon svg {
      fill: map-get($colors, "white") !important;
    }
    .c-logo__main, .c-logo__sub {
      color: map-get($colors, "white");
    }
  }

  &--gray {
    .c-logo__icon svg {
      fill: map-get($grays, "gray-2") !important;
    }
    .c-logo__main, .c-logo__sub {
      color: map-get($grays, "gray-2");
    }
  }

  &--navy {
    .c-logo__icon svg {
      fill: map-get($colors, "navy") !important; // 예시로 진한 네이비
    }
    .c-logo__main, .c-logo__sub {
      color: map-get($colors, "navy");
    }
  }

  /* 사이즈 옵션 */
  &--small {
    .c-logo__icon svg {
      width: rem(30);
      height: rem(30);
    }
    .c-logo__main {
      font-size: rem(16);
    }
    .c-logo__sub {
      font-size: rem(12);
    }
  }

  &--medium {
    .c-logo__icon svg {
      width: rem(50);
      height: rem(50);
    }
    .c-logo__main {
      font-size: rem(20);
    }
    .c-logo__sub {
      font-size: rem(15);
    }
  }

  &--large {
    .c-logo__icon svg {
      width: rem(70);
      height: rem(70);
    }
    .c-logo__main {
      font-size: rem(30);
    }
    .c-logo__sub {
      font-size: rem(24);
    }
  }
}
