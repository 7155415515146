/* StepLine.scss */
// 단계별 색상 매핑
$state-colors: (
        0: map-get($theme-colors, states0),
        1: map-get($theme-colors, states1),
        2: map-get($theme-colors, states2),
        3: map-get($theme-colors, states3),
        4: map-get($theme-colors, states4),
);

.c-stepline {
    position: relative;
    line-height: 1.2;

    /* 각 아이템들이 세로로 나열 */
    &__item {
        position: relative;
        padding: rem(5) 0 rem(5) rem(30);
    }

    /* 왼쪽 원 */
    &__circle {
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(10);
        height: rem(10);
        border: rem(2) solid; // 기본 상태: 상위 단계 클래스에서 적용
        border-radius: 50%;
        background-color: map-get($colors, "white");
        transform: translateY(-50%);
        z-index: 1;
    }

    /* 세로 선 */
    &__line {
        position: absolute;
        left: rem(4);
        width: rem(2);
        height: 100%;
        background-color: inherit; // 상위 단계 클래스에서 색상을 지정
        top: rem(12);
    }

    /* 오른쪽 내용 영역 */
    &__label-value {
        display: flex;
        align-items: center;
    }

    &__content {
        display: flex;
        align-items: center;
    }
    &__label-value {
        flex: 1;
        display: flex;
        align-items: center;
        width: 100%;
    }
    &__label-value-item {
        flex: 1;
        display: flex;
        align-items: center;
    }
    &__label {
        display: block;
        min-width: rem(60);
        margin-right: rem(20);
        color: map-get($colors, "navy");
        font-size: rem(18);
        font-weight: 700;
    }
    &__value {
        flex: 1;
        display: block;
        font-size: rem(18);
        color: map-get($colors, "text");
    }

    &__list {
        flex: 1;
    }

    &__list-item {
        display: flex;
        gap: rem(16);
        margin-bottom: rem(4);
    }

    /* 동행서비스 알림 라인 */
    &__alert-controls {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: rem(10);
    }

    &--small {
        .c-stepline__item {
            padding-left: rem(20);
        }
        .c-stepline__label,
        .c-stepline__value {
            font-size: rem(12);
        }
        .c-stepline__label {
            min-width: rem(55);
            margin-right: 0;
        }
        .c-stepline__line {
            top: 0.9rem
        }
    }
}

/* 단계별 색상 적용 */
@for $i from 0 through 4 {
    .c-stepline--state#{$i} {
        .c-stepline__circle {
            border-color: map-get($state-colors, $i);
        }
        .c-stepline__line {
            background-color: map-get($state-colors, $i);
        }
    }
}

/* small 상태에서의 추가 스타일:
   - c-stepline--small와 단계별 클래스가 동시에 적용될 때,
   - .c-stepline__circle의 border-color를 흰색, background-color를 단계별 색상으로 재정의
*/
@for $i from 0 through 4 {
    .c-stepline--small.c-stepline--state#{$i} {
        .c-stepline__circle {
            border-color:map-get($colors, "white");
            background-color: map-get($state-colors, $i);
        }
    }
}
