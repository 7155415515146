/* AccordionItem.scss */
.c-accordion {
  border: 0;
  border-radius: rem(5);

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: rem(50);
    padding: 0 rem(30);
    background: none;
    border: rem(1) solid; /* 버튼의 borderColor는 인라인에서 stageColor로 설정 */
    border-radius: rem(5);
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;

    // &:hover {
    //   background-color: map-get($theme-colors, "background");
    // }

    /* 작은 버튼 스타일 */
    &--small {
      height: rem(45);
    }

    &.defaultCursor {
      cursor: auto;
    }
  }

  &__title {
    color: map-get($colors, "navy");
    font-size: rem(18);
    font-weight: 700;
    /* 텍스트 색상은 인라인 스타일 또는 여기서 지정 가능 */

    &.white {
      color: map-get($colors, "white");
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    svg {
      width: rem(20);
      height: rem(20);
      fill: map-get($colors, "navy");
    }

    &.white {
      svg {
        fill: map-get($colors, "white");
      }
    }
  }

  &__content {
    padding: 8px 12px;
  }
}
