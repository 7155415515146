.monitoring-group {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: rem(10);

  &:last-child {
    margin-bottom: 0;
  }

  &__total {

  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: rem(10);
  }
}