/* TabsLine.css */
/*
   BEM 네이밍을 사용해 탭 헤더 아래쪽에 라인(underline) 스타일을 적용.
   size="large"로 확장할 수 있는 Modifier 클래스도 정의.
*/

.c-tabs-line {
  &__header {
    display: flex;
    border-bottom: 1px solid map-get($grays, gray-2);
  }

  &__button {
    position: relative;
    margin-bottom: rem(-1);
    padding: 0 rem(50);
    height: rem(80);
    background: map-get($theme-colors, foreground);
    border: none;
    border-bottom: 1px solid map-get($grays, gray-2);
    cursor: pointer;
    color: map-get($grays, gray-2);
    font-size: rem(32);
    font-weight: 700;
    line-height: rem(80);
    transition: color 0.2s ease;

    &:hover {
      color: map-get($colors, text);
    }

    &.active {
      color: map-get($colors, text);
      // 활성 탭에 밑줄 강조
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 5px;
        background-color: map-get($theme-colors, primary);
      }
    }
  }

  &__content {
    padding: 1rem;
  }

  &--large {
    .c-tabs-line__button {
      font-size: 1.25rem;
      padding: 0.75rem 1.5rem;
    }
  }
}
