@import "./mixin";

.bw-flex-custom {
  flex:12 1 !important;
}

/* side bar area */
.bw-manhole {
  &-list {
    //width:100%;
    height:100%;
    display:inline-block;

    div {
      height:100%;
      display:flex;
      flex-flow:column nowrap;

      .tab-menu {
        height:50px;
        background: transparent;
      }
    }
  }

  /* background of manhole spot list  */
  &-spot {
    width:100%;
    height:100%;
    overflow:auto;

    ul {
      padding:15px 0 0 0;

      li {
        a {
          display:flex;
          justify-content: center;
          align-items: center;
          padding:5px;

          p {
            height:40px;
            line-height:40px;
            text-align: center;
            font-weight:600;
            font-size:18px;
            border-bottom:2px solid #adb5bd;
          }
        }
      }
    }

    &-circle {
      width:30px;
      height:30px;
      border-radius:50%;
      display:block;
      margin:0 15px 0 0 ;
    }

    &-step {
      width:100px;
      border-radius:10px 0 0 0;
      color:white;
      text-shadow:1px 1px 2px #6c757d;
      /*background:#27293d;*/
    }

    &-name {
      width:200px;
    }
  }

  /* ExampleMap style - manhole table,modal,list */
  &-table,
  &-modal{
    position:absolute;
    z-index:50;
    //width:100%;
  }

  &-table {
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    width:80%;
  }
  &-modal {
    left:30px;
    top:30px;
    width:40%;
  }

  .card-body {
    .table-responsive {
      overflow:auto;
    }

    .table {
      padding-bottom:0 !important;
      border: 1px #212529 solid ;
      width: 100%;
      border-collapse: collapse;
      border-radius: 5px;
      overflow: hidden;
      text-align: center;
      box-shadow: 10px 10px 10px rgba(52, 58, 64, 0.5);
      color: #e9ecef;
      margin:0 !important;

      > thead > tr > th {
        font-size:inherit !important;
        color:inherit !important;
      }
      > tbody > tr > td {
        color: #212529 !important;
        background: #f8f9fa;

      }
    }
  }

  &_td,
  &_thead {
    padding: 1em .5em !important;
    border: 1px solid rgba(108, 117, 125, 0.5) !important;
  }

  &_thead {
    background: #1e1e24 !important;
  }
}

/* tab-menu */
.tab-menu {
  ul {
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-around;
    margin:0;
    padding:0;
  }

  li {
    list-style: none;
    width:100%;
    padding:10px 0;
    text-align:center;

    &.active a {
      color:#e14eca;
    }
  }

  a {
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    line-height:25px;
    color:#dee2e6;
  }
}
