/* Tabs.css */
.c-tabs {
  overflow: hidden;

  &__header {
    display: flex;
  }

  &__button {
    display: block;
    height: rem(30);
    padding: 0 rem(12);
    border: rem(1) solid map-get($grays, gray-2);
    background-color: map-get($theme-colors, background);
    cursor: pointer;
    color: map-get($grays, gray-2);
    font-size: rem(14);
    font-weight: 700;
    line-height: rem(30);
    text-align: center;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:first-child {
      border-radius: rem(5) 0 0 rem(5);
    }

    &:last-child {
      border-radius: 0 rem(5) rem(5) 0;
    }

    &:hover {
      background-color: map-get($theme-colors, primary);
      color: map-get($colors, 'white');
    }

    &.active {
      background-color: map-get($theme-colors, primary);
      color: map-get($colors, 'white');
    }
  }

  &__content {
    padding: rem(20);
    background-color: #fff;
  }
}

.c-tabs.c-tabs--large .c-tabs {
  &__button {
    padding: 0 rem(24);
    height: rem(60);
    font-size: rem(18);
  }
}

.c-tabs.c-tabs--line .c-tabs {
  &__header {
    display: inline-flex;
  }
}




