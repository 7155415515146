/* Switch.scss */
.c-switch-wrapper {
  height: rem(24);
}
.c-switch {
  position: relative;
  display: inline-block;
  width: rem(45);
  height: rem(24);
  cursor: pointer;
  user-select: none;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;

    &:checked + .c-switch {
      .c-switch__slider {
        background-color: map-get($theme-colors, "primary");
        &::before {
          background-color: map-get($colors, "white");
          transform: translateX(rem(21));
        }
      }
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($grays, "gray-1");
    border-radius: rem(12);
    transition: background-color 0.2s;

    &::before {
      content: "";
      position: absolute;
      left: rem(3);
      top: rem(3);
      width: rem(18);
      height: rem(18);
      background-color: map-get($theme-colors, "foreground");
      border-radius: 50%;
      transition: transform 0.2s;
    }
  }

  //&--checked {
  //  .c-switch__slider {
  //    background-color: map-get($theme-colors, "primary");
  //    &::before {
  //      background-color: map-get($colors, "white");
  //      transform: translateX(rem(21));
  //    }
  //  }
  //}

  &--disabled {
    cursor: not-allowed;
    .c-switch__slider {
      background-color: map-get($grays, "gray-1");
      &::before {
        background-color: map-get($theme-colors, "foreground");
      }
    }
  }
}
