/* InputText.scss */
.c-input {
  box-sizing: border-box;
  width: 100%;
  background-color: map-get($colors, "white");
  border: rem(1) solid map-get($colors, "navy");
  border-radius: rem(5);
  padding: 0 rem(16);
  color: map-get($colors, "text");
  outline: none;
  // placeholder 스타일 (원하는 경우 수정)
  &::placeholder {
    color: map-get($grays, "gray-2");
  }

  // 사이즈별 스타일
  &--small {
    height: rem(30);
    font-size: rem(12);
    line-height: rem(30);
  }

  &--medium {
    height: rem(45);
    font-size: rem(20);
    line-height: rem(45);
  }

  &--large {
    height: rem(60);
    font-size: rem(20);
    line-height: rem(60);
  }

  &--login {
    height: rem(82);
    font-size: rem(24);
    line-height: rem(82);
  }

  // 상태 variant별 스타일은 필요한 경우 추가 (예: error, success 등)
  &--error {
    border-color: map-get($theme-colors, "danger");
    color: map-get($theme-colors, "danger");
    &::placeholder {
      color: map-get($theme-colors, "danger");
    }
  }

  &--success {
    border-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "primary");;
    &::placeholder {
      color: map-get($theme-colors, "primary");
    }
  }

  &--disabled {
    background-color: map-get($grays, "gray-1");;
    color: map-get($grays, "gray-2");;
    border-color: map-get($grays, "gray-1");
    cursor: not-allowed;
    &::placeholder {
      color: map-get($grays, "gray-2");
    }
  }
}
