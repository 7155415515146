/* Badge.scss */

/* 예시 색상:
   - grade0: 회색, 정상, 닫힘
   - grade1: 초록, 관심
   - grade2: 파랑, 주의, 열림
   - grade3: 주황, 경계
   - grade4: 빨강, 심각
*/
$badge-colors: (
        0: map-get($theme-colors, "states0"), // 정상
        1: map-get($theme-colors, "states1"), // 관심
        2: map-get($theme-colors, "states2"), // 주의
        3: map-get($theme-colors, "states3"), // 경계
        4: map-get($theme-colors, "states4"), // 심각
);

/* 글자색상: solid면 흰색, border면 grade 컬러 */
@function badge-color($grade, $variant) {
  $color: map-get($badge-colors, $grade);
  @if $variant == 'solid' {
    @return map-get($colors, "white");
  } @else {
    @return $color;
  }
}

/* 배경색상: solid면 grade 컬러, border면 투명 */
@function badge-bg($grade, $variant) {
  $color: map-get($badge-colors, $grade);
  @if $variant == 'solid' {
    @return $color;
  } @else {
    @return transparent;
  }
}

/* 테두리색상: border면 grade 컬러, solid면 투명 */
@function badge-border($grade, $variant) {
  $color: map-get($badge-colors, $grade);
  @if $variant == 'border' {
    @return $color;
  } @else {
    @return transparent;
  }
}

.c-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: rem(80);
  font-weight: 700;
  line-height: 1.2;

  &--small {
    padding: 0 rem(8);
    min-width: rem(60);
    height: rem(28);
    border-radius: rem(14);
    font-size: rem(12);
  }

  &--medium {
    padding: 0 rem(16);
    height: rem(42);
    border-radius: rem(21);
    font-size: rem(16);
  }

  &--large {
    padding: 0 rem(20);
    height: rem(50);
    border-radius: rem(25);
    font-size: rem(18);
  }

  // 5가지 grade(0~4)에 대해 각각 variant(solid/border)에 맞는 스타일을 적용
  @for $i from 0 through 4 {
    &--grade#{$i} {
      &.c-badge--solid {
        color: badge-color($i, 'solid');
        background-color: badge-bg($i, 'solid');
        border: rem(1) solid badge-border($i, 'solid');
      }
      &.c-badge--border {
        color: badge-color($i, 'border');
        background-color: badge-bg($i, 'border');
        border: rem(1) solid badge-border($i, 'border');
      }
    }
  }
}
