/* Dropdown.scss */
.c-dropdown {
  position: relative;
  display: inline-block;

  &--small {
    .c-dropdown__selected,
    .c-dropdown__option {
      padding: 0 rem(12);
      height: rem(30);
      font-size: rem(12);
      line-height: rem(30);
    }
  }

  &--medium {
    .c-dropdown__selected,
    .c-dropdown__option {
      padding: 0 rem(16);
      height: rem(45);
      font-size: rem(20);
      line-height: rem(45);
    }
  }

  &--large {
    .c-dropdown__selected,
    .c-dropdown__option {
      height: rem(60);
      font-size: rem(20);
      padding: 0 rem(16);
    }
  }

  &--disabled {
    .c-dropdown__selected {
      background-color: map-get($grays, gray-1);
      color: map-get($grays, gray-2);
      cursor: not-allowed;
      border-color: map-get($grays, gray-1);
    }
  }

  &--open {
    // 열렸을 때의 추가 스타일 (선택 사항)
    .c-dropdown__selected {
      // 예: border-color: #007bff;
    }
  }

  /* dropup 모드: 메뉴가 위로 표시되도록 */
  &--dropup {
    .c-dropdown__menu {
      top: auto;
      bottom: 100%;
      margin: 0 0 rem(4) 0;  // 필요에 따라 여백 조정
    }
  }

  &__selected {
    width: 100%;
    background-color: map-get($colors, "white");
    border: rem(1) solid map-get($grays, gray-1);
    border-radius: rem(5);
    color: map-get($colors, "text");
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;

    &:hover {
      background-color: map-get($theme-colors, foreground);
    }

    &:disabled {
      background-color: map-get($grays, gray-1);
      color: map-get($grays, gray-2);
      border-color: map-get($grays, gray-2);
      cursor: not-allowed;
    }
  }

  &__label {
    // 내부 텍스트 스타일 (필요에 따라 추가)
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-left: rem(8);
    svg {
      width: rem(16);
      height: rem(16);
      fill: map-get($grays, gray-2);
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin: rem(4) 0 0;
    padding: 0;
    background-color: map-get($colors, "white");
    border: rem(1) solid map-get($grays, gray-1);
    border-radius: rem(5);
    z-index: 999;
  }

  &__option {
    border-bottom: rem(1) solid map-get($grays, gray-1);
    cursor: pointer;
    color: map-get($colors, "text");
    &:hover {
      background-color: map-get($theme-colors, foreground);
    }
    &.selected {
      background-color: map-get($theme-colors, primary);
      color: map-get($colors, "white");
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
