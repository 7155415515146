/* StatesHeading.scss */
.c-states-heading {
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px; /* 공간 확보: 점과 텍스트 사이 */
}

/* 제목 앞에 점(•) 표시 */
.c-states-heading::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 16px;
  /* 점의 색상은 부모의 color 값을 상속받습니다. */
}

/* 상태 레벨별 색상 지정 */
.c-states-heading--state0 {
  color: #9CA3AF;  /* 안전 */
}

.c-states-heading--state1 {
  color: #22C55E;  /* 관심 */
}

.c-states-heading--state2 {
  color: #3B82F6;  /* 주의 */
}

.c-states-heading--state3 {
  color: #F59E0B;  /* 경계 */
}

.c-states-heading--state4 {
  color: #EF4444;  /* 심각 */
}
